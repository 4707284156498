<template>
  <div class="publish-product-form-images-upload">
    <div class="publish-product-form-images-upload-icon-box">
        <p v-if="asRequired" class="publish-product-form-images-upload-icon">*</p>
    </div>
    <p class="publish-product-form-images-upload-title">商品截图</p>
    <div class="publish-product-form-images-upload-productScreenshot">
        <ul>
            <div class="publish-product-form-images-upload-btn-box">
                <img class="publish-product-form-images-upload-btn-icon" src="/images/home/my_center_images_upload_bg.png" alt="上传按钮" >
            </div>
            <li v-for="(item,index) in uploadImages" :key="index">
                <img class="publish-product-form-images-upload-image-show" :src="item" alt="上传图片" >
                <img @click="clickDeleteImageHandler(index)" class="publish-product-form-images-upload-image-icon" src="/images/home/my_center_upload_delete_icon.png" alt="删除图片" >
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    name:"PublishProductFormImagesUpload",
    props:{
        title:{
            type:String,
            default:'',
            required:true
        },
        aliasTitle:{
            type:String,
            default:'',
            required:true
        },
        asRequired:{
            type:Boolean,
            default:false,
            required:true
        },
    },
    data(){
        return {
            uploadImages:[
                // "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/23e8ce2fd87e4e60bcceb6dbc0b6db04_mergeImage.png",
                // "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/274c9e84ced14fcca3d21c015be30c09_mergeImage.png",
                // "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/23e8ce2fd87e4e60bcceb6dbc0b6db04_mergeImage.png",

            ]
        }
    },
    methods:{
        clickDeleteImageHandler(index){
            console.log('当前索引是：'+index)
            this.uploadImages = this.uploadImages.splice(index,1);
        }
    }
}
</script>

<style lang="less" scoped>
.publish-product-form-images-upload{
    width: 1100px;
    margin-top: 10px;
    background: #FFFFFF;
    margin-left: 50px;
    display: flex;
    .publish-product-form-images-upload-icon-box{
        margin-top: 20px;
        width: 8px;
        height: 21px;
        .publish-product-form-images-upload-icon{
            width: 8px;
            height: 21px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #E74335; 
            line-height: 21px;
            text-align: left;
            font-style: normal;
            cursor: default;
        }
    }
    .publish-product-form-images-upload-title{
        margin-top: 20px;
        width: 112px;
        margin-left: 6px;
        height: 19px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: #131415;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        cursor: default;
    }
    .publish-product-form-images-upload-productScreenshot{
        margin-left: 24px;
        width:950px;
        ul{
            list-style-type: none;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            .publish-product-form-images-upload-btn-box{
                margin-top: 20px;
                width: 100px;
                height: 100px;
                background: #F5F5F5;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                .publish-product-form-images-upload-btn-icon{
                    width: 26px;
                    height: 24px;
                }
            }
            li{
                margin-top: 20px;
                margin-left: 20px;
                list-style: none;
                width: 100px;
                height: 100px;
                background: #F5F5F5;
                border-radius: 4px;
                position: relative;
                padding: 0px;
                .publish-product-form-images-upload-image-show{
                    width: 100px;
                    height: 100px;
                }
                .publish-product-form-images-upload-image-icon{
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
                &:nth-child(8n+1){
                    margin-left: 0px;
                }
            }
        }
    }

}
</style>