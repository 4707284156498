<template>
  <div class="publish-product-form-checkbox">
    <div class="publish-product-form-checkbox-icon-box">
        <p v-if="asRequired" class="publish-product-form-checkbox-icon">*</p>
    </div>
    
    <p class="publish-product-form-checkbox-title">{{title}}</p>
    <div class="publish-product-form-checkbox-item-box">
        <ul>
            <li v-for="(item,index) in dataList" :key="index" @click="clickCheckboxItemHandler(index,item)" > 
                <div  class="publish-product-form-checkbox-item-icon" :style="currentCheckboxSelectIndex===index?checkboxItemIconStyle:''">
                    <img v-if="tempSelectData.some(itemn=>itemn==item)" class="publish-product-form-checkbox-item-icon-inner" alt="icon"  :src="tempSelectData.some(itemn=>itemn==item)?'/images/home/my_center_checkbox_selected_icon.png':''">
                </div>
                <div class="publish-product-form-checkbox-item-title">{{item}}</div>
            </li>
            <li @click="clearAllSelect()" > 
                <div  class="publish-product-form-checkbox-item-icon" :style="clearSelect?checkboxItemIconStyle:''">
                    <img v-if="clearSelect" class="publish-product-form-checkbox-item-icon-inner" alt="icon"  :src="clearSelect?'/images/home/my_center_checkbox_selected_icon.png':''">
                </div>
                <div class="publish-product-form-checkbox-item-title">无</div>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    name:"PublishProductFormCheckbox",
    props:{
        title:{
            type:String,
            default:'',
            required:true
        },
        aliasTitle:{
            type:String,
            default:'',
            required:true
        },
        dataList:{
            type:Array,
            default:()=>[],
            required:true
        },
        asRequired:{
            type:Boolean,
            default:false,
            required:true
        },
    },
    data(){
        return{
            checkboxItemData:{
                title:'星元皮肤',
                allValue:['金色仲夏夜','零号雷霆','幽冥之眼','时之愿境','幽冥火','时之思念','幻海之心','时之羁绊','未来之甲']
            },
            currentCheckboxSelectIndex:-1,
            tempSelectData:[],
            clearSelect:false
        }
    },
    methods:{
        //用户点击项
        clickCheckboxItemHandler(index,item){
            this.clearSelect=false;
            this.currentCheckboxSelectIndex = index;
            //只要还没选，或者之前没有被选中的，就添加到临时列表中。
            if(this.tempSelectData.length===0||!this.tempSelectData.some((itemm)=>{
                return item===itemm;
            })){
                //把当前选中项目添加到临时列表
                this.tempSelectData.push(item);
            }else{
                //否则，从临时列表中删除已经选中了的
                for(let i=0;i<this.tempSelectData.length;i++){
                    if(this.tempSelectData[i]===item){
                        this.tempSelectData.splice(i,1)
                    }
                }
                console.log('删除后---'+JSON.stringify(this.tempSelectData))
            }
        },
        //清空所选
        clearAllSelect(){
            //清空临时列表
            this.tempSelectData=[];
            //同时改变图标
            this.clearSelect=!this.clearSelect;
        }
    },
    computed:{
        checkboxItemIconStyle(){
            return "border: 1px solid #F02233";
        },
        isShowClearAllBtnIcon(){
            return this.currentCheckboxSelectIndex+1 === this.checkboxItemData.allValue.length;
        }
    }
}
</script>

<style lang="less" scoped>
.publish-product-form-checkbox{
    width: 1100px;
    margin-top: 10px;
    background: #FFFFFF;
    margin-left: 50px;
    display: flex;
    .publish-product-form-checkbox-icon-box{
        margin-top: 20px;
        width: 8px;
        height: 21px;
        .publish-product-form-checkbox-icon{
            width: 8px;
            height: 21px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #E74335;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            cursor: default;
        }
    }
    .publish-product-form-checkbox-title{
        margin-top: 20px;
        width: 112px;
        margin-left: 6px;
        height: 19px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: #131415;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        cursor: default;
    }
    .publish-product-form-checkbox-item-box{
        width: 950px;
        margin-left: 24px;
        ul{
            width: 950px;
            list-style-type: none;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            cursor: default;
            li{
                margin-top: 20px;
                list-style: none;
                margin-right: 50px;
                display: flex;
                .publish-product-form-checkbox-item-icon{
                    width: 16px;
                    height: 16px;
                    background: #FFFFFF;
                    border: 1px solid #C7C7C7;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .publish-product-form-checkbox-item-icon-inner{
                        width: 16px;
                        height: 16px;
                    }
                }
                .publish-product-form-checkbox-item-title{
                    margin-left: 10px;
                    height: 17px;
                    font-family: MicrosoftYaHei;
                    font-size: 13px;
                    color: #000000;
                    line-height: 17px;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
    }
}
</style>