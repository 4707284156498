<template>
  <div class="publish-product-form-area">
    <div class="publish-product-form-area-icon-box">
        <p v-if="asRequired" class="publish-product-form-area-icon">*</p>
    </div>
    <p class="publish-product-form-area-title">{{title}}</p>
    <textarea maxlength="350" class="publish-product-form-area-box" :placeholder="placeholder"></textarea>
  </div>
</template>

<script>
export default {
    name:"PublishProductFormArea",
    props:{
        title:{
            type:String,
            default:'',
            required:true
        },
        aliasTitle:{
            type:String,
            default:'',
            required:true
        },
        placeholder:{
            type:String,
            default:'',
            required:true
        },
        asRequired:{
            type:Boolean,
            default:false,
            required:true
        },
    }
}
</script>

<style lang="less" scoped>
.publish-product-form-area{
    width: 1100px;
    margin-top: 10px;
    background: #FFFFFF;
    margin-left: 50px;
    display: flex;
    .publish-product-form-area-icon-box{
        margin-top: 20px;
        width: 8px;
        height: 21px;
        .publish-product-form-area-icon{
            width: 8px;
            height: 21px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #E74335;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            cursor: default;
        }
    }

    .publish-product-form-area-title{
        margin-top: 20px;
        width: 112px;
        margin-left: 6px;
        height: 19px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: #131415;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        cursor: default;
    }
    .publish-product-form-area-box{
        margin-top: 20px;
        height: 132px;
        margin-left: 24px;
        width: 860px;
        height: 132px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        outline: none;
        padding: 10px 14px 10px 14px;
        &::placeholder{
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #C5C5C5;
            line-height: 19px;
            text-align: left;
            font-style: normal;
        }
    }
}
</style>